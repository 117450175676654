/******************************** Import libs ************************************/
import { getListByApi, viewDataByApi } from "./actions";
import { URL_CONSTANTS } from "./urls";

const getPillarList = (params) => {
  return getListByApi(URL_CONSTANTS.pillars, params);
};

const getPillarById = (dataId) => {
  return viewDataByApi(URL_CONSTANTS.pillars, dataId);
};

const getGoalList = (params) => {
  return getListByApi(URL_CONSTANTS.goals, params);
};

const getGoalById = (dataId) => {
  return viewDataByApi(URL_CONSTANTS.goals, dataId);
};

const getUserList = (params) => {
  return getListByApi(URL_CONSTANTS.users, params);
};

const getUserById = (dataId) => {
  return viewDataByApi(URL_CONSTANTS.users, dataId);
};

const getSponsorsUsers = (params) => {
  return getListByApi(URL_CONSTANTS.sponsorsUsers, params);
};


const getProjectList = (params) => {
  return getListByApi(URL_CONSTANTS.projects, params);
};

const getProjectById = (dataId) => {
  return viewDataByApi(URL_CONSTANTS.projects, dataId);
};

const getProjectsOfUser = (params) => {
  return getListByApi(URL_CONSTANTS.projectsOfUser, params);
};

const getGoalsForPillarId = (dataId) => {
  return viewDataByApi(URL_CONSTANTS.getGoalsForPillars, dataId);
};

const getSponsorDetails = (dataId) => {
  return viewDataByApi(URL_CONSTANTS.sponsorDetails, dataId);
};

const getResourceDetails = (dataId) => {
  return viewDataByApi(URL_CONSTANTS.resourceDetails, dataId);
};

const getAllSponsor = (params) => {
  return getListByApi(URL_CONSTANTS.sponsorList, params);
};

const getAllResource = (params) => {
  return getListByApi(URL_CONSTANTS.resourceList, params);
};

const getPayReleaseByApi = (params) => {
  return getListByApi(URL_CONSTANTS.payRelease, params);
};

const getDashBoardCount = (params) => {
  return getListByApi(URL_CONSTANTS.dashboard, params);
};


const getAllRequest = (params) => {
  return getListByApi(URL_CONSTANTS.request, params);
};

const getRequestById = (dataId) => {
  return viewDataByApi(URL_CONSTANTS.request, dataId);
};

const getRequestService = (params) => {
  return getListByApi(URL_CONSTANTS.requestService, params);
};

const getRequestPayment = (params) => {
  return getListByApi(URL_CONSTANTS.requestPayment, params);
};

const getProposedProjects = (params) => {
  return getListByApi(URL_CONSTANTS.proposedProject, params);
};

const getLocation = (params) => {
  return getListByApi(URL_CONSTANTS.location, params);
};

const getLogs = (params) => {
  return getListByApi(URL_CONSTANTS.logs, params);
};

const getLogsUpdate = (params) => {
  return getListByApi(URL_CONSTANTS.logsUpdate, params);
};

const getLogsUndo = (params) => {
  return getListByApi(URL_CONSTANTS.logsUndo, params);
};

const getActiveStatus = (params) => {
  return getListByApi(URL_CONSTANTS.activeStatus, params);
};

export {
  getPillarList,
  getPillarById,

  getGoalList,
  getGoalById,

  getUserList,
  getUserById,
  getSponsorsUsers,

  getAllSponsor,
  getSponsorDetails,

  getAllResource,
  getResourceDetails,

  getProjectList,
  getProjectById,
  getProjectsOfUser,

  getGoalsForPillarId,
  getPayReleaseByApi,
  getDashBoardCount,

  getAllRequest,
  getRequestById,
  getRequestService,
  getRequestPayment,
  getProposedProjects,
  getLocation,

  getLogs,
  getLogsUpdate,
  getLogsUndo,
  getActiveStatus,
};
