import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 220;

export const useAppStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        color: "black",
      },
      palette: {
        primary: {
          main: "#9acad6 ",
        },
        secondary: {
          main: "black",
        },
      },
      primary: {
        "&:hover": {
          backgroundColor: "#9acad6 ",
          color: "black",
        },
      },
      appBar: {
        zIndex: theme.zIndex.drawer + 1,
        // transition: theme.transitions.create(["width", "margin"], {
        //   easing: theme.transitions.easing.sharp,
        //   duration: theme.transitions.duration.leavingScreen,
        // }),
      },
      appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        // transition: theme.transitions.create(["width", "margin"], {
        //   easing: theme.transitions.easing.sharp,
        //   duration: theme.transitions.duration.enteringScreen,
        // }),
      },
      menuButton: {
        marginRight: 12,
      },
      hide: {
        display: "none",
      },
      drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
        color:"white",
        backgroundColor:"white",
      },
      drawerOpen: {
        width: drawerWidth,
        color:"white",
        // transition: theme.transitions.create("width", {
        //   easing: theme.transitions.easing.sharp,
        //   duration: theme.transitions.duration.enteringScreen,
        // }),
      },
      drawerClose: {
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        color:"white",
        backgroundColor:"white",
        overflowX: "hidden",
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up("sm")]: {
          width: theme.spacing(9) + 1,
        },
      },
      toolbar: {
        color: "white",
        backgroundColor:"#4e9a98",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
      },
      sideMenu: {
        color:"#9acad6 ",
        backgroundColor:"#4e9a98",
        height:"100vh",
     
      },
      sideMenuItem:{
        padding:"0.625rem 0",
      },
      customWidth: {
        backgroundColor:"#4e9a98",
        paddingRight:"0px"
      },
      content: {
        flexGrow: 1,
        padding: theme.spacing(7),
      },
    }));