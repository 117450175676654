/******************************** Import Package ************************************/

import React from "react";
import pillarIcon from "../Assets/icons/pillarIcon.svg";
import dashboardIcon from "../Assets/icons/dashboardIcon.svg";
import payReleaseIcon from "../Assets/icons/payReleaseIcon.svg";
import userIcon from "../Assets/icons/userIcon.svg";
import projectIcon from "../Assets/icons/projectIcon.svg";
import goalIcon from "../Assets/icons/goalIcon.svg";
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import HistoryIcon from '@material-ui/icons/History';
import { FolderSpecial } from "@material-ui/icons";
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import EditLocationIcon from '@material-ui/icons/LocationOn';
import { Timeline } from '@material-ui/icons';
/******************************** Admin Menu************************************/
const adminMenu = [
  {
    id: "dashboard",
    name: "Dashboard",
    link: "dashboard",
    icon: <img alt="Dashboard" src={dashboardIcon} />,
  },
  {
    id: "requests",
    name: "Pending Requests",
    link: "pending-approval",
    icon: <HourglassEmptyIcon style={{ color: "white", fontSize: 30 }} />,
  },
  {
    id: "totalRequests",
    name: "Request History",
    link: "request",
    icon: <HistoryIcon style={{ color: "white", fontSize: 30 }} />,
  },
  {
    id: "projects",
    name: "All Projects and Committees ",
    link: "projects",
    icon: <img alt="All Projects and Committees " src={projectIcon} />,
  },
  {
    id: "users",
    name: "Users",
    link: "users",
    icon: <img alt="User" src={userIcon} />,
  },
  {
    id: "pillars",
    name: "Pillars",
    link: "pillars",
    icon: <img alt="Pillar" src={pillarIcon} />,
  },
  {
    id: "goals",
    name: "Goals",
    link: "goals",
    icon: <img alt="Goal" src={goalIcon} />,
  },
  {
    id: "location",
    name: "Location",
    link: "location",
    icon: <EditLocationIcon style={{ color: "white", fontSize: 30 }} />,
  },
  {
    id: "payRelease",
    name: "Payroll Reports",
    link: "reports",
    icon: <img alt="Payroll Reports" src={payReleaseIcon} />,
  },
  {
    id: "loginHistory",
    name: "Login History",
    link: "log-in-history",
    icon: <Timeline style={{ color: "white", fontSize: 30 }} />,
  },

];

/******************************** Approver Menu************************************/
const approverMenu = [
  {
    id: "dashboard",
    name: "Dashboard",
    link: "dashboard",
    icon: <img alt="Dashboard" src={dashboardIcon} />,
  },
  {
    id: "requests",
    name: "Pending Requests",
    link: "pending-approval",
    icon: <HourglassEmptyIcon style={{ color: "white", fontSize: 30 }} />,
  },
  {
    id: "totalRequests",
    name: "Request History",
    link: "request",
    icon: <HistoryIcon style={{ color: "white", fontSize: 30 }} />,
  },
  // {
  //   id: "users",
  //   name: "Users",
  //   link: "users",
  //   icon:  <img alt="User" src={ userIcon } />,
  // },  
  {
    id: "projects",
    name: "All Projects and Committees ",
    link: "projects",
    icon: <img alt="All Projects and Committees " src={projectIcon} />,
  },
  {
    id: "pillars",
    name: "Pillars",
    link: "pillars",
    icon: <img alt="Pillar" src={pillarIcon} />,
  },
  {
    id: "goals",
    name: "Goals",
    link: "goals",
    icon: <img alt="Goal" src={goalIcon} />,
  },




];

/******************************** Sponsor Menu************************************/
const sponsorMenu = [
  {
    id: "dashboard",
    name: "Dashboard",
    link: "dashboard",
    icon: <img alt="Dashboard" src={dashboardIcon} />,
  },
  // {
  //   id: "pendingRequests",
  //   name: "Pending Requests",
  //   link: "sponsor/pending-requests",
  //   icon:  <HourglassEmptyIcon style={ { color:"white", fontSize: 30 } } />,
  // },
  // {
  //   id: "myRequests",
  //   name: "My Request History",
  //   link: "sponsor/requests",
  //   icon:  <HistoryIcon style={ { color:"white", fontSize: 30 } } />,
  // },
  // {
  //   id: "users",
  //   name: "Users",
  //   link: "users",
  //   icon:  <img alt="Users" src={ userIcon } />,
  // },
  {
    id: "projects",
    name: "All Projects and Committees ",
    link: "projects",
    icon: <img alt="All Projects and Committees " src={projectIcon} />,
  },
  {
    id: "myProjects",
    name: "My Projects and Committees",
    link: "sponsor/my-projects",
    icon: <FolderSpecial style={{ color: "white", fontSize: 30 }} />,
  },
  {
    id: "pillars",
    name: "Pillars",
    link: "pillars",
    icon: <img alt="Pillar" src={pillarIcon} />,
  },
  {
    id: "goals",
    name: "Goals",
    link: "goals",
    icon: <img alt="Goal" src={goalIcon} />,
  },

];

/******************************** Resource Menu************************************/
const resourceMenu = [
  {
    id: "dashboard",
    name: "Dashboard",
    link: "dashboard",
    icon: <img alt="Dashboard" src={dashboardIcon} />,
  },
  {
    id: "projects",
    name: "All Projects and Committees ",
    link: "user/projects",
    icon: <img alt="All Projects and Committees " src={projectIcon} />,
  },
  {
    id: "myProjects",
    name: "My Projects and Committees",
    link: "user/my-projects",
    icon: <FolderSpecial style={{ color: "white", fontSize: 30 }} />,
  },
  {
    id: "pendingRequests",
    name: "Pending Requests",
    link: "user/pending-requests",
    icon: <HourglassEmptyIcon style={{ color: "white", fontSize: 30 }} />,
  },
  {
    id: "myRequests",
    name: "My Request History",
    link: "user/requests",
    icon: <HistoryIcon style={{ color: "white", fontSize: 30 }} />,
  },
];

/********************************Export************************************/
export {
  adminMenu,
  approverMenu,
  sponsorMenu,
  resourceMenu,
};
