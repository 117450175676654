const getUserSessionData = () => {
  const userData = JSON.parse(localStorage.getItem("loggedUser"));
  return userData;
};

const getUserSessionId = () => {
  const userData = JSON.parse(localStorage.getItem("loggedUser"));
  return userData ? userData.id : null;
};

const getCurrentRole = () => {
  const currentRole = JSON.parse(localStorage.getItem("currentUser"));
  return currentRole ? currentRole : null;
};

const getRefreshToken= () => {
  const refreshToken = localStorage.getItem("refreshToken");
  return refreshToken ? refreshToken : null;
};

export {
  getUserSessionData,
  getUserSessionId,
  getCurrentRole,
  getRefreshToken,
};
