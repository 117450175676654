/******************************** Import Package ************************************/
import React from "react";
import Card from "../../Components/Card/Card";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import "./DisplayDashboard.css";
import { displayAmount } from "../../Utils/common";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px",
  },
 }));

export default function DashBoard({ dashBoardLinks, dashboardData, dashBoardAccess }) {
  const classes = useStyles()

  return (
    <>
      <div className="dashboard-container" id="dashboard">
          {dashBoardAccess.adminView ? (
            <Grid container>
            <Grid item xs={8} className="dashBoard-box-item-title" >
              Payments Pending($)
              </Grid>
                <Grid item xs={4}className="dashBoard-box-item-value" >
              {displayAmount(dashboardData.approvedAmount)}
            </Grid>
            </Grid>
          ) :( dashBoardAccess.userView ? 
            <Grid container>
                <Grid item  md={3} sm={8} xs={8} className="dashBoard-box-item-title" >
               Pending Payment Requests ($) 
                </Grid>
                <Grid item md ={3} sm={4} xs={4}className="dashBoard-box-item-value" >
               {displayAmount(dashboardData.inReviewAmount)}
                </Grid>
                <Grid item md ={3} sm={8} xs={8} className="dashBoard-box-item-title" >
                Approved, Pending Payment ($)
               </Grid>
               <Grid item md ={3} sm={4} xs={4} className="dashBoard-box-item-value" >
               {displayAmount(dashboardData.approvedAmount)}
                </Grid>
                {/* <Grid item sm={2} xs={8} className="dashBoard-box-item-title" >
               Paid Last Quarter ($) 
               </Grid>
               <Grid item sm={2} xs={4} className="dashBoard-box-item-value" >
               {displayAmount(dashboardData.paidAmount)}
              </Grid> */}
              </Grid>
            :null)}
      </div>
      
      <Grid container className={`${classes.root} card-container`} direction="row"
  justify="center"  alignItems="center">
        {dashBoardLinks.map((item) => {
          return (
            <Card
              key={item.id}
              name={item.name}
              value={dashboardData[item.id]}
              icon={item.icon}
              link={item.link}
            />
          );
        })}
      </Grid>
    </>
  );
}
