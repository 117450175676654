import React, { useState } from "react";
import RoleBasedRoute from "./Routes";
import Login from "./Pages/Login";
import Notification from "./Components/Notification";
import { env, hostConfig } from "./Config";
import GoogleAuth from "./Pages/GoogleAuth";
const App = () => {
    const [notify, setNotify] = useState({
        isOpen: false,
        message: "",
        type: "",
    });

    const responseHandler = (res) => {
        if (res) {
            if (res.code) {
                if (res.code === 400) {
                    setNotify({
                        isOpen: true,
                        message: res.message,
                        type: "error",
                    });
                    return false;
                } else {
                    setNotify({
                        isOpen: true,
                        message: "Bad Request",
                        type: "error",
                    });
                    return false;
                }
            } else if (res.error) {
                setNotify({
                    isOpen: true,
                    message: res.error,
                    type: "error",
                });
                return false;
            } else {
                return res;
            }
        } else {
            return false;
        }
    };
    return (
        <>
            {JSON.parse(localStorage.getItem("loggedUser")) ? (
                <RoleBasedRoute />
            ) : (
                window.location.pathname==="/google/auth"?
                 <GoogleAuth />
                 :(
                 (env === 4 || env === 2) ?
                window.location.href = `${hostConfig.API_URL}auth/google`
                :<Login responseHandler={responseHandler} />
            ))}
            <Notification notify={notify} setNotify={setNotify} />
        </>
    );
};
export default App;
