/******************************** Import Package ************************************/
import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import "./SideNavigation.css";
import { useLocation } from "react-router-dom"

/******************************** SideNavigation Stye ************************************/
const useStyles = makeStyles((theme) => ({
  navigate: {
    textDecoration: "none",
    color: "white",
    background: "#4e9a98",

  },
  sideNav: {
    background: "#4e9a98",
    "&:hover": {
      background: "#9acad6 ",
    },
  },
  icon: {
    color: "white",
  },
}));

/******************************** SideNavigation  ************************************/
const SideNavigate = ({ link, name, icon ,open}) => {
  const classes = useStyles();
  const location = useLocation();
  return (
    <Link className={ classes.navigate } to={ `/${link}` }>
      <ListItem button className={ classes.sideNav }
       style={ `${location.pathname}` === `/${link}` ? { background: "#9acad6 " } : { background: "" } } >
        <ListItemIcon className={ `${classes.icon} tool-tip` }>{icon} <span className="tool-tip-text">{name}</span></ListItemIcon>
        <ListItemText className={`${open?"sideMenu":null}`} primary={ name } />
      </ListItem>
    </Link>
  );
};

export default SideNavigate;
