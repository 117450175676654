/******************************** Import Package ************************************/
import React from "react";
import { FormControl, InputLabel } from "@material-ui/core";
import { Select as MuiSelect } from "@material-ui/core";
import { MenuItem, FormHelperText } from "@material-ui/core";
/******************************** Select  ************************************/

export default function SelectTitle(props) {
  const { name, label, value, error = null, onChange, options, ...other } = props;
  return (
    <FormControl variant="outlined" 
     { ...(error && { error: true }) }>
      <InputLabel>{label}</InputLabel>
      <MuiSelect 
      label={ label } name={ name } value={ value } onChange={ onChange } { ...other }>
        {options.map((item) => (
          <MenuItem key={ item.id } value={ item.title }>
            {item.title}
          </MenuItem>
        ))}
      </MuiSelect>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}
