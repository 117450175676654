/******************************** Import Package ************************************/
import React from "react";
import { FormControl, InputLabel } from "@material-ui/core";
import { Select as MuiSelect } from "@material-ui/core";
import { MenuItem, FormHelperText } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox, ListItemText, Chip } from '@material-ui/core';
/******************************** Select  ************************************/
const useStyles = makeStyles((theme) => ({
  formList:{
    maxWidth:400,
    whiteSpace:"initial",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    maxWidth:400,
    whiteSpace:"initial",
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

export default function SelectValue(props) {
  const {
    name,
    label,
    value,
    error = null,
    onChange,
    options,
    defaultValue,
    ...other
  } = props;
  const classes = useStyles();

  const findSelectedData = (data, selectedItem) => {
    if (data.title === selectedItem) {
      return data
    }
  }

  return (
    <FormControl variant="outlined" className="multi-select multiple" {...(error && { error: true })}>
      <InputLabel>{label}</InputLabel>
      <MuiSelect

multiple
        label={label}
        name={name}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        renderValue={(selected) => (
          <div className={classes.chips}>
            {selected.map((selectedItem) => {
              let selectedData = options.find(data => findSelectedData(data, selectedItem)) || []
              return <Chip key={selectedData.id} label={selectedData.title} className={classes.chip} />
            }
            )}
          </div>
        )}
        {...other}
      >
        {options.map((item) => (
          <MenuItem key={item.id} value={item.title}  className={classes.formList}>
            <Checkbox checked={value.indexOf(item.title) > -1} />
            <ListItemText primary={item.title}  />
          </MenuItem>
        ))}
      </MuiSelect>

      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}