/******************************** Import Package ************************************/
import React, { useState } from "react";

import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Controls from "../../Components/Controls";
import {
  Typography,
  Link,
  Box,
  TextField,
  CssBaseline,
  Avatar,
  Container,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import { postLoginRequestData } from "../../Api/create";

import Notification from "../../Components/Notification";
import "./login.css";
import GoogleLogo from "./googleLogo.png";
import { hostConfig } from "../../Config";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link
        color="inherit"
        href="http://www.pacificmedicalgroup.org/"
        target="_blank"
      >
        <span className="tooltip">
          <span className="tooltip-text">
            PACIFIC INPATIENT MEDICAL GROUP
                        </span>
                        PIMG,</span>
      </Link>
      all rights reserved. {new Date().getFullYear()}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  maingrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: "1",
    position: "absolute",
    top: 0,
    left: 0,
    margin: "0px",
    padding: "0px",
    width: "100%",
    height: "auto",
    backgroundColor: "#9acad6"
  },
}));

const SignIn = () => {
  const classes = useStyles();
  const { handleSubmit, register } = useForm();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  if (JSON.parse(localStorage.getItem("loggedUser"))) {
    window.location.href = "/dashboard";
  }
  const currentUserRole = (userRole) => {
    if (userRole.includes("admin")) {
      localStorage.setItem("currentUser", "admin");
    } else if (userRole.includes("approver")) {
      localStorage.setItem("currentUser", "approver");
    } else if (userRole.includes("sponsor")) {
      localStorage.setItem("currentUser", "sponsor");
    } else {
      localStorage.setItem("currentUser", "resource");
    }
  }

  const onSubmit = handleSubmit((data) => {
    if (data.email && data.password) {
      postLoginRequestData(data).then((res) => {
        if (responseHandler(res)) {
          let loggedUser = res.user;
          let userRole = res.user.role;
          let accessToken = res.tokens.access.token;
          let refreshToken = res.tokens.refresh.token;
          let accessExpiry = res.tokens.access.expires;
          let refreshExpiry = res.tokens.refresh.expires;
          currentUserRole(userRole)
          localStorage.setItem("accessExpiry", accessExpiry);
          localStorage.setItem("refreshExpiry", refreshExpiry);
          localStorage.setItem("accessToken", accessToken);
          localStorage.setItem("refreshToken", refreshToken);
          localStorage.setItem("loggedUser", JSON.stringify(loggedUser));
          if (JSON.parse(localStorage.getItem("loggedUser"))) {
            setNotify({
              isOpen: true,
              message: "Logged in successfully",
              type: "success",
            });
            window.location.href = "/dashboard";
          } else {
            setNotify({
              isOpen: true,
              message: "Invalid details",
              type: "error",
            });
          }
        }
      });
    } else {
      setNotify({
        isOpen: true,
        message: "Invalid details",
        type: "error",
      });
    }
  });

  const responseHandler = (res) => {
    if (res) {
      if (res.code) {
        if (res.code === 400) {
          setNotify({
            isOpen: true,
            message: res.message,
            type: "error",
          });
          return false;
        } else {
          setNotify({
            isOpen: true,
            message: "Bad Request",
            type: "error",
          });
          return false;
        }
      } else if (res.error) {
        setNotify({
          isOpen: true,
          message: res.error,
          type: "error",
        });
        return false;
      } else {
        return res;
      }
    } else {
      return false;
    }
  };


  return (
    // "main-grid"
    <Grid container spacing={0} className={classes.maingrid}>
      <Grid item xs={5} className="left-image-main">
        <div className="left-image" ></div>
      </Grid>
      <Grid item md={6} s={12} xs={12} className="form-grid">
        <Container component="main" className="login-form" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <form className={classes.form} onSubmit={onSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                inputRef={register}
                autoFocus
                className={`${classes.textField} form-textField`}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="password"
                label="Password"
                type="password"
                inputRef={register}
                id="password"
                autoComplete="current-password"
                className={`${classes.textField} form-textField`}
              />
              {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}
              <Controls.ActionButton
                type="submit"
                // fullWidth
                variant="contained"
                color="primary"
                className={`${classes.submit} form-textField`}
              >
                Sign In
              </Controls.ActionButton >
            </form>
            <br />
            <a href={`${hostConfig.API_URL}auth/google`}>
              <img src={GoogleLogo} alt="Google sign in" className="pointer" />
            </a>
          </div>
          <Box mt={8}>
            <Copyright />
            <center>Last Update: 23/Mar/2021</center>
            <center>Data Version: V26</center>
          </Box>
          <Notification notify={notify} setNotify={setNotify} />
        </Container>
      </Grid>
    </Grid>
  );
};

export default SignIn;
