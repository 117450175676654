/* 

Component : common

*/
/** ****************************** Import Packages *************************** */
import "moment-timezone";
import moment from "moment";


const displayDate = (date, format = "MM/DD/YYYY") => {
  
  const formatted = moment(date)
  if (formatted.isValid()) {
    const tzFormat = format.replace(" z", "").replace("z", " ");
    return formatted.format(`${tzFormat}`);
  }
  return formatted;
};

const displayFormattedDate = (date) => { 
if(date){
  const formatted =new Date(date).toISOString();
  return formatted;
}
};

const displayAmountWithComma = (amount) => {
  if (amount) {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
};

const leadsZero = (input) => `0${input}`.slice(-2);

const displayAmount = (currentAmount) => {
  if (currentAmount || currentAmount === 0) {
    const amount = parseFloat(currentAmount).toFixed(2);
    const result = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return `$${result}`;
  }
};

const displayDollarAmount = (currentAmount) => {
  if (currentAmount || currentAmount === 0) {
    const amount = parseInt(currentAmount, 10);
    const result = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return `$${result}`;
  }
};

const displayCentAmount = (currentAmount) => {
  if (currentAmount || currentAmount === 0) {
    const indexValue = String(currentAmount).indexOf(".");
    const result = String(currentAmount).slice(indexValue);
    return `.${leadsZero(result)}`;
  }
};

const displayNameLetters = (firstName, lastName) => {
  const result =
    firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();
  return result;
};

const displayName = (firstName, lastName) => {
  const result = `${firstName} ${lastName}`;
  return result;
};

const displayEin = (ein) => {
  let result;
  if (ein.length === 9) {
    result = `${ein[0] + ein[1]}-${ein[2]}${ein[3]}${ein[4]}`;
    result += `${ein[5]}${ein[6]}${ein[7]}${ein[8]}`;
  } else if (ein.length === 8) {
    result = `0${ein[0]}-${ein[1]}${ein[2]}${ein[3]}`;
    result += `${ein[4]}${ein[5]}${ein[6]}${ein[7]}`;
  }
  return result;
};

const displayPhone = (phoneNumber) => {
  const cleaned = `${phoneNumber}`.replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return null;
};

const displayDescription = (description) => {
  if (description) {
    return description.replace(/<\/?[a-z][a-z0-9]*[^<>]*>/gi, "");
  }
};
/******************************Display Date******************************************* */
const displayHours = (hours) => {
  if (hours) {
   let result= hours>1?  hours+ " hours" : hours+ " hour"
   return result;
  }
};

export {
  displayDate,
  displayAmountWithComma,
  displayAmount,
  displayDollarAmount,
  displayCentAmount,
  displayName,
  displayNameLetters,
  displayEin,
  displayPhone,
  displayDescription,
  leadsZero,
  displayFormattedDate,
  displayHours,
};
