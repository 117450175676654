/******************************** Import Package ************************************/
import React from "react";
import { FormControl, InputLabel } from "@material-ui/core";
import { Select as MuiSelect } from "@material-ui/core";
import { MenuItem, FormHelperText } from "@material-ui/core";
/******************************** Select  ************************************/


export default function Select(props) {
  const {
    name,
    label,
    value,
    error = null,
    onChange,
    options,
    defaultValue,
    valuesOnly,
    capitalize,
    ...other
  } = props;

  return (
    <FormControl variant="outlined"  
     { ...(error && { error: true }) }>
      <InputLabel>{label}</InputLabel>
      <MuiSelect     
        label={ label }
        name={ name }
        value={ value }
        defaultValue={ defaultValue }
        onChange={ onChange }
        { ...other }
      >
        {valuesOnly?null:<MenuItem value="">None</MenuItem>}
        {options.map((item) => (
          <MenuItem key={ item.id } value={ item.id } className={capitalize?"capitalize":null}>
            {item.title}
          </MenuItem>
        ))}
      </MuiSelect>

      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}
