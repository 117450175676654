import AdminRoutes from "./AdminRoutes";
import ApproverRoutes from "./ApproverRoutes";
import SponsorRoutes from "./SponsorRoutes";
import UserRoutes from "./UserRoutes";

const RoleBasedRoute = () => {
    
    let currentUser = localStorage.getItem("currentUser");
let RoleBasedRoute;
    if (currentUser === "admin") {
        RoleBasedRoute=<AdminRoutes/>
    } else if (currentUser === "approver") {
        RoleBasedRoute=<ApproverRoutes/>
    } else if (currentUser === "sponsor") {
        RoleBasedRoute=<SponsorRoutes/>
    } else {
        RoleBasedRoute=<UserRoutes/>
    }
  
    return RoleBasedRoute;
}

export default RoleBasedRoute;