import moment from "moment";

/**Display request type in Readable format */
const displayType = (type) => {
  if (type) {
    switch (type) {
      case "sign_up":
        return "Sign Up";
      case "add_hours":
        return "Add Hours";
      default:
        return type;
    }
  }
};


/**Set color based on  status**/
const statusColor = (status) => {
  switch (status) {
    case "pending":
      return "orange"
    case "approved":
      return "green"
    case "rejected":
      return "red"
    case "paid":
      return "green"
    default:
      return null
  }
}

/**Display last name , first  name**/
const displayName = (user) => {
  if (user) {
    if ( user.firstName) {
    let result = user.lastName + ", " + user.firstName;
    return result;
    } else {
      return user;
    }
  } else {
    return "-";
  }
}

/**Display project status in Readable format */
const projectStatus = (status) => {
  if (status) {
    switch (status) {
      case "inProgress":
        return "In Progress"
      case "notStarted":
        return "Not Started"
      case "completed":
        return "Completed"
      case "closing":
        return "Closing"
      case "closed":
        return "Closed"
  default:
        return null
    }
  }   
}

/**Set color based on  status**/
const projectStatusColor = (status) => {
  if (status) {
    switch (status) {
      case "inProgress":
        return "GoldenRod"
      case "notStarted":
        return "CadetBlue "
      case "completed":
        return "green"
      case "closing":
        return "IndianRed"
      case "closed":
        return "red"
      default:
        return null
    }
  }   

  switch (status) {
    case "pending":
      return "orange"
    case "approved":
      return "green"
    case "rejected":
      return "red"
    case "paid":
      return "green"
    default:
      return null
  }
}

/****DIsplay  Custom date Format  MM/DD/YYYY***** */
const displayDate = (date) => {
  if (date) {
    let tempDate=new Date(date)
    let month=("00" + (tempDate.getMonth()+1)).slice(-2);
    let newDate=("00" + (tempDate.getDate())).slice(-2);
    return `${month}/${newDate}/${tempDate.getFullYear()}`
  } else {
    return "-"
  }
}

/****DIsplay  Time HH:MM AM/PM */
const displayTime = (date) => {
  if (date) {
    let tempDate=new Date(date)
    let hours = tempDate.getHours();
  let minutes = tempDate.getMinutes();
  let amPm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  let strTime = hours + ':' + minutes + ' ' + amPm;
  return strTime
} else {
    return "-"
  }
}


/****DIsplay   date and Time    -- works only in react 17 or jsx syntax only*/
const displayDateAndTime = (date) => {
  if (date) {
  return <div><p>{displayDate(date)}</p><p>{displayTime(date)}</p></div>
} else {
    return null
  }
}




/****Custom date Format   YYYY-MM-DD (for database)**** */
const setDateFormat = (date) => {
  if (date) {
    let tempDate=new Date(date)
    let month=("00" + (tempDate.getMonth()+1)).slice(-2);
    let newDate=("00" + (tempDate.getDate())).slice(-2);
    return `${tempDate.getFullYear()}-${month}-${newDate}`
  } else {
    return null
  }
}

/****Display All hoursToAdd  data Before launch as NOt Available**** */
const checkHoursToAdd = (data) => {
      if (Date.parse(data.createdAt) > Date.parse('2021-01-19T00:00:01.000Z')) {
       return data.hoursToAdd
     } else {
      return "Not Available"
    }
}


/****Display All grantedHours data Before launch as NOt Available****** */
const checkGrantedHours = (data) => {
  if(Date.parse(data.createdAt) > Date.parse('2021-01-19T00:00:01.000Z')){
    return data.grantedHours
  }else{
   return "Not Available"
 }
  }

  /****Display User Role (admin as Administrator)****** */
  const displayUserRole = (role) => {
    if(role==="admin"){
      return "Administrator";
    }else if(role==="resource"){
      return "User";
    }else{
     return role;
   }
    }    

    //Check if a date is valid or not
    const isValidDate = (dateString) => {
     return  moment(dateString, 'MM/DD/YYYY',true).isValid(); 
};

export {
  displayType,
  statusColor,
  displayName,
  projectStatus,
  projectStatusColor,
  displayDate,
  displayTime,
  displayDateAndTime,
  setDateFormat,
  checkHoursToAdd,
  checkGrantedHours,
  displayUserRole,
  isValidDate,
};
