/******************************** Import Package ************************************/
import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";

/******************************** Import Style ************************************/
import "./Card.css";

/******************************** Card Style ************************************/
const useStyles = makeStyles({
  root: {
    boxShadow: 3,
    },
  media: {
    height: "100%",
  },
  alignment: {
    textAlign: "center",
  },
  alignment1: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
   width:"40%",
   background:"#4e9a98"
  },
 
  LinkClass:{
    display:"flex",
    flexDirection:"row",
    "&:nth-of-type(1)": { flex: 1 },
    "&:nth-of-type(2)": { flex: 3 },
  },
  cardValue:{
    display:"flex",
    flexDirection:"column",
    width:"100%",
    color:"#4e9a98"
  },
  cardContent:{
  padding:"2rem",
  },
  space:{
    color:"white",
  },
});

/******************************** Card  ************************************/
export default function Cards(props) {
  const { name, value, icon, link } = props;
  const classes = useStyles();
  return (
    <Grid item xs={ 12 }  sm={6} md={6} lg={4} xl={3}>
      <Card className={ `dashboard-card ${classes.root}` }>
        <Link to={ `/${link}` } className={ classes.LinkClass }>
          <CardActionArea className={ classes.alignment1 } >
            <CardContent className={ classes.cardContent }>
              <Typography
                gutterBottom
                variant="h5"
                component="h6"
                align="left"
                className={ `space ${classes.space}` }
              >
                {icon}
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions className={ `${classes.cardValue} ${classes.alignment}` }>
            <Typography gutterBottom variant="h6"  component="p">
            {name}
            </Typography>
            <Typography gutterBottom variant="h4"  component="p">
              {value}
            </Typography>
          </CardActions>
        </Link>
      </Card>
    </Grid>
  );
}
