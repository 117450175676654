/******************************** Import libs ************************************/
import { postDataApi,postDataWithParamsApi } from "./actions";
import { URL_CONSTANTS } from "./urls";

const postProjectData = (params) => {
  return postDataApi(URL_CONSTANTS.projects, params);
};
const postPillarData = (params) => {
  return postDataApi(URL_CONSTANTS.pillars, params);
};
const postGoalData = (params) => {
  return postDataApi(URL_CONSTANTS.goals, params);
};
const postUserData = (params) => {
  return postDataApi(URL_CONSTANTS.users, params);
};
const postPayReleaseData = (params) => {
  return postDataApi(URL_CONSTANTS.payRelease, params);
};

const postLoginRequestData = (params) => {
  return postDataApi(URL_CONSTANTS.login, params);
};
const postRegisterRequestData = (params) => {
  return postDataApi(URL_CONSTANTS.register, params);
};
const postLogoutRequestData = (params) => {
  return postDataApi(URL_CONSTANTS.logout, params);
};
const postRefreshTokensData = (params) => {
  return postDataApi(URL_CONSTANTS.refreshTokens, params);
};
const postForgotPasswordData = (params) => {
  return postDataApi(URL_CONSTANTS.forgotPassword, params);
};
const postResetPasswordData = (params) => {
  return postDataApi(URL_CONSTANTS.resetPassword, params);
};

const postSponsorDetails = (params) => {
  return postDataApi(URL_CONSTANTS.sponsorDetails, params);
};

const PostResourceDetails = (params) => {
  return postDataApi(URL_CONSTANTS.resourceDetails, params);
};

const PostRequestHours = (params) => {
  return postDataApi(URL_CONSTANTS.requestHours, params);
};

const PostAllRequest = (params) => {
  return postDataApi(URL_CONSTANTS.request, params);
};

const PostProposedProject = (params) => {
  return postDataApi(URL_CONSTANTS.proposedProject, params);
};

const PostRequestFilter = (data,params) => {
  return postDataWithParamsApi(URL_CONSTANTS.filterRequest,data, params);
};

const PostLocation = (params) => {
  return postDataApi(URL_CONSTANTS.location, params);
}

const postFilterProjects = (data,params) => {
  return postDataWithParamsApi(URL_CONSTANTS.filterProjects,data, params);
};

export {
  postProjectData,
  postFilterProjects,
  postPillarData,
  postGoalData,
  postUserData,

  postPayReleaseData,

  postLoginRequestData,
  postRegisterRequestData,
  postLogoutRequestData,
  postRefreshTokensData,
  postForgotPasswordData,
  postResetPasswordData,

  postSponsorDetails,
  PostResourceDetails,

  PostRequestHours,
  PostAllRequest,
  PostProposedProject,
  PostRequestFilter,

  PostLocation,
};
