/******************************Import Packages **************************************/
import React, { useState, useEffect } from "react";

/******************************Import Components ************************************/
import DisplayDashboard from "./DisplayDashboard";
import Loader from "../../Components/Loader";
import Notification from "../../Components/Notification";

/******************************Import API ******************************************/
import { getDashBoardCount, getActiveStatus } from "../../Api/list";

/******************************** Import Constants **********************************/
import { refreshInterval, activeStatusCallTime } from "../../Config"

export default function DashBoard({ dashBoardLinks, dashBoardAccess, responseHandler }) {
  const [dashboardData, setDashboardData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [notify, setNotify] = useState({ isOpen: false, message: "", type: "" });

  useEffect(() => {
    getActiveStatus("")
    let intervalId = setInterval(() => {
      getActiveStatus("")
    }, activeStatusCallTime)
    return () => clearInterval(intervalId);
  }, []);

  const initialize = () => {
    let role = localStorage.getItem("currentUser")
    getDashBoardCount({ role }).then((res) => {
      if (responseHandler(res)) {
        res.myRequests = res.services + res.payments;
        setDashboardData(res);
      }
      setLoading(false);
    });
  }

  useEffect(() => {
    initialize()
  }, []);


  useEffect(() => {
    const interval = setInterval(() => { initialize() }, refreshInterval);
    return () => clearInterval(interval);
  }, [refreshInterval]);


  const renderDashboardData = () => {
    return (
      <>
        <DisplayDashboard dashBoardLinks={dashBoardLinks} dashboardData={dashboardData} dashBoardAccess={dashBoardAccess} />
        <Notification notify={notify} setNotify={setNotify} />
      </>
    );
  };

  return <>{loading ? <Loader loading /> : renderDashboardData()}</>;
}
