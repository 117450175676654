/******************************** Import Package ************************************/
import Input from "./Input.jsx";
import Select from "./Select";
import SelectValue from "./SelectValue";
import SelectTitle from "./SelectTitle";
import Checkbox from "./Checkbox";
import DatePicker from "./DatePicker";
import Button from "./Button";
import ActionButton from "./ActionButton";
import MultiSelectTitle from "./MultiSelectTitle";
import MultiSelectId from "./MultiSelectId";
import Password from "./Password";

const Controls = {
  Input,
  Select,
  SelectValue,
  SelectTitle,
  Checkbox,
  DatePicker,
  Button,
  ActionButton,
  MultiSelectTitle,
  MultiSelectId,
  Password,
};

export default Controls;
