/******************************** Import Package ************************************/
import React from "react";

import GoogleLogo from "../Login/googleLogo.png";
import UnauthorizedLogo from "./unauthorized.png";
import { hostConfig, homeURL, stagingHomeURL, env } from "../../Config";
import HomeTwoToneIcon from '@material-ui/icons/HomeTwoTone';

import "./Unauthorized.css";

const Unauthorized = () => {
  return (
    <div className="Unauthorized flex">
      <h1>Sorry!</h1>
      <p>You ar not allowed to access this page</p>
      <img src={UnauthorizedLogo} alt="Unauthorized" />
      {env === 4 ?
        <a href={`${homeURL}`}>
          <HomeTwoToneIcon style={{ color: " #4e9a98", fontSize: 60 }} />
        </a> : (env === 2 ? <a href={`${stagingHomeURL}`}>
          <HomeTwoToneIcon style={{ color: " #4e9a98", fontSize: 60 }} />
        </a> : null)}
      <a href={`${hostConfig.API_URL}auth/google`}>
        <img src={GoogleLogo} alt="Google sign in" className="pointer" />
      </a>
    </div>
  );
};

export default Unauthorized;
