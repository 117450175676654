/********************************Admin Rights*********************************************/
const adminRights = {
  dashboard: { add: true, edit: true, delete: true, view: true ,adminView:true},
  pillars: { add: true, edit: true, delete: true, view: true },
  goals: { add: true, edit: true, delete: true, view: true },
  users: { add: true, edit: true, delete: true, view: true },
  projects: { add: true, edit: true, delete: true, view: true ,approve:true,admin:true, close:true},
  myProjects: { add: false, edit: false, delete: false, view: false },
  services: { add: false, edit: false, delete: false, view: true ,admin:true },
  payments: { add: false, edit: false, delete: false, view: true, approve: true, admin:true },
  payRelease: { add: false,edit: false, delete: false,view: true,approve: true },
  location:{ add: true,edit: true, delete: true,view: true},
  pendingRequestAccess:{ add: true,edit: true, delete: true,view: true,approve: true }
};
/********************************Approver Rights*********************************************/
const approverRights = {
  dashboard: { add: true, edit: true, delete: true, view: true , adminView:true},
  pillars: { add: false, edit: false, delete: false, view: false },
  goals: { add: false, edit: false, delete: false, view: false },
  users: { add: false, edit: false, delete: false, view: false },
  projects: { add: false, edit: false, delete: false, view: true, approve:true },
  myProjects: { add: false, edit: false, delete: false, view: false },
  services: { add: true, edit: false, delete: false, view: true },
  payments: { add: false,edit: false, delete: false,view: true,approve: true },
  payRelease: { add: false,edit: false, delete: false,view: true,approve: true },
  pendingRequestAccess:{ add: false,edit: true, delete: true,view: true,approve: true }

};
/********************************Resource Rights*********************************************/
const resourceRights = {
  dashboard: { add: true, edit: true, delete: true, view: true, userView:true },
  pillars: { add: false, edit: false, delete: false, view: false },
  goals: { add: false, edit: false, delete: false, view: false },
  users: { add: false, edit: false, delete: false, view: false },
  projects: { add: false, edit: false, delete: false, view: true ,user:true },
  myProjects: { add: false, edit: false, delete: false, view: true },
  services: { add: true, edit: false, delete: false, view: false,user:true },
  payments: { add: false, edit: false, delete: false, view: false, approve: false,user:true },
  payRelease: { add: false,edit: false, delete: false,view: false, approve: false },
  pendingRequestAccess:{ add: false,edit: false, delete: false,view: false,approve: false }

};
/********************************Ssponsor Rights*********************************************/
const sponsorRights = {
  dashboard: { add: true, edit: true, delete: true, view: true},
  pillars: { add: false, edit: false, delete: false, view: false },
  goals: { add: false, edit: false, delete: false, view: false },
  users: { add: false, edit: false, delete: false, view: false },
  projects: { add: false, edit: false, delete: false, view: true },
  myProjects: { add: false, edit: false, delete: false, view: true },
  services: { add: false, edit: false, delete: false, view: true },
  payments: { add: false,edit: false, delete: false,view: true, approve: false },
   payRelease: { add: false,edit: false, delete: false,view: false, approve: false },
   pendingRequestAccess:{ add: false,edit: false, delete: false,view: false,approve: false }
};

export { adminRights, approverRights, sponsorRights, resourceRights };