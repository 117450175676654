/******************************** Import Package ************************************/
import React from "react";

/******************************** Import Icons ************************************/
import pillarIcon from "../Assets/icons/pillarIcon.svg";
// import payReleaseIcon from "../Assets/icons/payReleaseIcon.svg";
import userIcon from "../Assets/icons/userIcon.svg";
import projectIcon from "../Assets/icons/projectIcon.svg";
import goalIcon from "../Assets/icons/goalIcon.svg";
import HistoryIcon from "@material-ui/icons/History";
import { FolderSpecial } from "@material-ui/icons";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
// import EditLocationIcon from '@material-ui/icons/LocationOn';

/******************************** Admin DashBoard************************************/
const adminDashBoard = [
  {
    id: "requests",
    name: "Pending Requests",
    link: "pending-approval",
    icon: <HourglassEmptyIcon style={{ color: "white", fontSize: 30 }} />,
  },
  {
    id: "totalRequests",
    name: "Request History",
    link: "request",
    icon: <HistoryIcon style={{ color: "white", fontSize: 30 }} />,
  },
  {
    id: "projects",
    name: "All Projects and Committees ",
    link: "projects",
    icon: <img alt="All Projects and Committees " src={projectIcon} />,
  },
  {
    id: "users",
    name: "Users",
    link: "users",
    icon: <img alt="User" src={userIcon} />,
  },
  {
    id: "pillars",
    name: "Pillars",
    link: "pillars",
    icon: <img alt="Pillar" src={pillarIcon} />,
  },
  {
    id: "goals",
    name: "Goals",
    link: "goals",
    icon: <img alt="Goal" src={goalIcon} />,
  },
];

/******************************** Approver DashBoard************************************/
const approverDashBoard = [
  {
    id: "requests",
    name: "Pending Requests",
    link: "pending-approval",
    icon: <HourglassEmptyIcon style={{ color: "white", fontSize: 30 }} />,
  },
  {
    id: "totalRequests",
    name: "Request History",
    link: "request",
    icon: <HistoryIcon style={{ color: "white", fontSize: 30 }} />,
  },
  {
    id: "projects",
    name: "All Projects and Committees ",
    link: "projects",
    icon: <img alt="All Projects and Committees " src={projectIcon} />,
  },
  {
    id: "pillars",
    name: "Pillars",
    link: "pillars",
    icon: <img alt="Pillar" src={pillarIcon} />,
  },
  {
    id: "goals",
    name: "Goals",
    link: "goals",
    icon: <img alt="Goal" src={goalIcon} />,
  },
];

/******************************** Sponsor DashBoard************************************/
const sponsorDashBoard = [

  {
    id: "projects",
    name: "All Projects and Committees ",
    link: "projects",
    icon: <img alt="All Projects and Committees " src={projectIcon} />,
  },
  {
    id: "myProjects",
    name: "My Projects and Committees",
    link: "sponsor/my-projects",
    icon: <FolderSpecial style={{ color: "white", fontSize: 30 }} />,
  },
  {
    id: "pillars",
    name: "Pillars",
    link: "pillars",
    icon: <img alt="Pillar" src={pillarIcon} />,
  },
  {
    id: "goals",
    name: "Goals",
    link: "goals",
    icon: <img alt="Goal" src={goalIcon} />,
  },
];

/******************************** Resource DashBoard************************************/
const resourceDashBoard = [
  {
    id: "projects",
    name: "All Projects and Committees ",
    link: "user/projects",
    icon: <img alt="All Projects and Committees " src={projectIcon} />,
  },
  {
    id: "myProjects",
    name: "My Projects and Committees",
    link: "user/my-projects",
    icon: <FolderSpecial style={{ color: "white", fontSize: 30 }} />,
  },
  {
    id: "requests",
    name: "My Pending Requests",
    link: "user/pending-requests",
    icon: <HourglassEmptyIcon style={{ color: "white", fontSize: 30 }} />,
  },
  {
    id: "totalRequests",
    name: "My Request History",
    link: "user/requests",
    icon: <HistoryIcon style={{ color: "white", fontSize: 30 }} />,
  },
];

const AdminRoute=["/login","/google/auth","dashboard","/unauthorized","/pending-approval","/request","/projects","/users","/pillars","/goals"];
const ApproverRoute=["/login","/google/auth","dashboard","/unauthorized","/pending-approval","/request","/projects","/pillars","/goals"];
const SponsorRoute=["/login","/google/auth","dashboard","/unauthorized","/projects","/sponsor/my-projects","/pillars","/goals"];
const UserRoute=["/login","/google/auth","dashboard","/unauthorized","/user/projects","/user/my-projects","/user/requests"];

export {
  adminDashBoard,AdminRoute,
  approverDashBoard,ApproverRoute,
  sponsorDashBoard,SponsorRoute,
  resourceDashBoard,UserRoute,
};
