/******************************** Import libs ************************************/

const URL_CONSTANTS = {
  pillars: "pillars",
  goals: "goals",
  getGoalsForPillars: "goals/getGoals",

  projects: "projects",
  projectsOfUser: "projects/myProjects",
  filterProjects: "projects/projectFilter",

  users: "users",
  sponsorList: "users/userSponsor",
  sponsorDetails: "users/sponsor",  
  sponsorsUsers: "users/sponsoredUser",
  resourceList: "users/userResource",
  resourceDetails: "users/resource",

  payRelease: "payRelease",
  dashboard: "dashboard",

  login: "auth/login",
  register: "auth/register",
  logout: "auth/logout",
  refreshTokens: "auth/refresh-tokens",
  google: "auth/google",

  request: "request",
  requestService: "request/myServices",
  requestPayment: "request/myPayments",
  proposedProject: "proposedProject",
  filterRequest: "request/requestFilter",
  location:"location",

  logs: "logs",
  logsUpdate: "updateLogs",
  logsUndo: "updateLogs/undo",
  activeStatus: "users/activeStatus",

};

export { URL_CONSTANTS };
