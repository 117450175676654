/******************************** Import Package ************************************/
import React from "react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import 'date-fns';

/******************************** Date Picker ************************************/
export default function DatePicker(props) {
  const { name, label, value, onChange, error = null, ...other } = props;
  const convertToDefEventPara = (name, value) => ({
    target: {
      name,
      value,
    },
  });

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        inputVariant="outlined"
        label={label}
        format="MM/dd/yyyy"
        name={name}
        value={value ? value : null}
        onChange={(date) => onChange(convertToDefEventPara(name, date))}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        minDate={new Date(1994, 1, 1)}  //y, m, d
        maxDate={new Date(2050, 1, 31)}
        autoOk
        {...other}
        {...(error && { error: true, helperText: error })}
      />
    </MuiPickersUtilsProvider>
  );
}